import React, { useEffect, useState } from "react";
import {
  Avatar,
  styled,
  Tooltip,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "./gridNoRowOverLay";
import AssignmentLateTwoToneIcon from "@mui/icons-material/AssignmentLateTwoTone";
import AssignmentTurnedInTwoToneIcon from "@mui/icons-material/AssignmentTurnedInTwoTone";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

import EditNoteIcon from "@mui/icons-material/EditNote";
import VerifiedIcon from "@mui/icons-material/Verified";
import JBWAgreementsDetailDialog from "./JBWAgreementsDetailDialog";
import moment from "moment";
import InvoiceDetailsDialog from "./InvoiceDetailsDialog";
import JBWClaimsIframDialog from "./JBWClaimsIframDialog";
import { envConfig } from "../services/configs/envConfig";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Active": {
    backgroundColor: "#71d68e",
    "&:hover": {
      backgroundColor: "#4ECC72",
    },
  },
  "& .super-app-theme--InActive": {
    backgroundColor: "#e4c553",
    "&:hover": {
      backgroundColor: "#DEB729",
    },
  },
}));

const ClaimsGrid = ({
  claimList,
  showDealer = false,
  showLoading = false,
  onRejectClaim,
  onVerifyClaim,
  onUpdateClaim,
  tenant,
}) => {
  const columns = [
    {
      field: "id",
      headerName: "",
      width: 45,
      sortable: false,
      renderCell: (params) => {
        const claimId = params.row.jbwClaimId;
        const bgColor =
          claimId === null || claimId === "" ? "#DEB729" : "#4ECC72";
        const iconDisaply =
          claimId === null || claimId === "" ? (
            <AssignmentLateTwoToneIcon />
          ) : (
            <AssignmentTurnedInTwoToneIcon />
          );
        return <Avatar sx={{ bgcolor: bgColor, m: 1 }}>{iconDisaply}</Avatar>;
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwAgreementId",
      headerName: "JBW Agreement Id",
      width: 140,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const agreementLink = params.row.agreementlink;
        const agreementPurchaseDate = params.row.agreementPurchaseDate
          ? moment(params.row.agreementPurchaseDate).format("MM/DD/YYYY")
          : "NA";
        const warrantyStartDate = params.row.warrantyStartDate
          ? moment(params.row.warrantyStartDate).format("MM/DD/YYYY")
          : "NA";
        const warrantyEndDate = params.row.warrantyEndDate
          ? moment(params.row.warrantyEndDate).format("MM/DD/YYYY")
          : "NA";
        const infoItems = [
          `Agreement Purchase Date : ${agreementPurchaseDate}`,
          `Warranty Start Date : ${warrantyStartDate}`,
          `Warranty End Date :${warrantyEndDate}`,
        ];
        const tip = infoItems.join("\n");
        return (
          <>
            {agreementLink ? (
              <Tooltip
                title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}
              >
                {/* <span className="table-cell-trucate">{params.value}</span> */}
                <Link className="table-cell-trucate" underline="always">
                  {params.value}
                </Link>
              </Tooltip>
            ) : (
              <Tooltip
                title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}
              >
                <span className="table-cell-trucate">{params.value}</span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "productCode",
      headerName: "Plan Id/Invoice Id",
      width: showDealer ? 140 : 160,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const proCode = params.row.productCode;
        const poNumber = params.row.poNumber;
        const invoiceId = params.row.invoiceId;
        return (
          <Stack>
            <Typography variant="string" component="span">
              {proCode}
            </Typography>
            <Typography variant="string" component="span">
              <Link className="table-cell-trucate" underline="always">
                {poNumber ? poNumber : invoiceId}
              </Link>
            </Typography>
          </Stack>
        );
      },
    },
    // {
    //   field: "invoiceId",
    //   headerName: "Invoice ID",
    //   width: showDealer ? 100 : 110,
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (param) => {
    //     return <Link underline="always">{param.value}</Link>;
    //   },
    // },
    {
      field: "customerName",
      headerName: "Customer Details",
      width: showDealer ? 220 : 240,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const custName = params.row.customerName;
        const loc = params.row.location;
        return (
          <Stack>
            <Typography variant="string" component="span">
              {custName}
            </Typography>
            <Typography variant="string" component="span">
              {loc}
            </Typography>
          </Stack>
        );
      },
    },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   width: showDealer ? 200 : 210,
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "jbwClaimId",
      headerName: "Claim Id",
      width: showDealer ? 100 : 110,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "invoiceSummary",
      headerName: "Invoice Summary",
      width: showDealer ? 200 : 210,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "claimStatus",
      headerName: "Claim Status",
      width: showDealer ? 160 : 180,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      cellClassName: "actions",
      width: 80,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        //console.log("params", params.row);
        const isClaimFiled = params.row.isClaimFiled;
        let cellItems = [];
        //isreadyfor jwd = 0
        if (isClaimFiled === 0) {
          cellItems.push(
            <GridActionsCellItem
              icon={<EditNoteIcon fontSize="medium" />}
              label="Create Claim"
              className="textPrimary"
              onClick={handleCreateClaim(params)}
              color="secondary"
              title="Create Claim"
            />
          );
          cellItems.push(
            <GridActionsCellItem
              icon={<UnpublishedIcon fontSize="medium" />}
              label="Reject Claim"
              className="textPrimary"
              onClick={handleRejectClaim(params)}
              color="error"
              title="Reject Claim"
            />
          );
        } else {
          cellItems.push(
            <GridActionsCellItem
              icon={<VerifiedIcon fontSize="medium" />}
              label="Check Claim Status"
              className="textPrimary"
              onClick={handleVerifyClaim(params)}
              color="primary"
              title="Check Claim Status"
            />
          );
        }

        return cellItems;
      },
      sortable: false,
    },
  ];

  if (showDealer) {
    columns.splice(1, 0, {
      field: "dealerId",
      headerName: "Dealer ID",
      width: 90,
      sortable: false,
      headerAlign: "center",
      align: "center",
    });
  }

  const [selectedAgreementId, setSelectedAgreementId] = useState();
  const [openAgreementDetailDialog, setOpenAgreementDetailDialog] =
    useState(false);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [openInvoiceDetailDialog, setOpenInvoiceDetailDialog] = useState(false);
  const [claimAgreementId, setClaimAgreementId] = useState();
  const [claimDealerId, setClaimDealerId] = useState();
  const [selectedClaim, setSelectedClaim] = useState();
  const [claimsList, setClaimsList] = useState([]);

  const [openJBWIframeClaimDialog, setOpenJBWIframeClaimDialog] =
    useState(false);

  // const handleEdit = (param, optype) => () => {
  //   console.log("Edit clicked ", param.row);
  //   //openEquimentDialog(param.row, optype);
  // };

  const handleRejectClaim = (params) => () => {
    onRejectClaim(params.row);
  };
  const handleVerifyClaim = (params) => () => {
    onVerifyClaim(params.row);
  };

  const handleCellClick = (params) => {
    //console.log("cell click event :", params);
    const columnName = params.field;
    const cellValue = params.value;
    //console.log(`Selected column : ${columnName} and cell value ${cellValue}`);
    if (columnName === "jbwAgreementId") {
      //setSelectedAgreementId(cellValue);
      //setOpenAgreementDetailDialog(true);
      const agreementLink = params.row.agreementlink;
      if (!agreementLink) return;
      window.open(agreementLink, "_blank");
    } else if (columnName === "productCode") {
      //console.log("selected invoice id", cellValue);
      //setSelectedInvoiceId(params.row.invoiceId);
      //setOpenInvoiceDetailDialog(true);
      const invoiceUrl = `${envConfig.STUrl}#/EditInvoice/${params.row.invoiceId}`;
      console.log("invoice st url :", invoiceUrl);
      window.open(invoiceUrl, "_blank");
    }
  };

  const handleAgreementDetailDialogClose = () => {
    setOpenAgreementDetailDialog(false);
  };

  const handleInvoiceDetailDialogClose = () => {
    setSelectedInvoiceId("");
    setOpenInvoiceDetailDialog(false);
  };

  const handleJBWIframeClaimDialogClose = (param) => {
    console.log("claim grid : ", param);
    setClaimDealerId();
    setClaimAgreementId();
    onUpdateClaim({ ...selectedClaim, ...param });
    setOpenJBWIframeClaimDialog(false);
  };

  const handleCreateClaim = (params) => () => {
    console.log("selected cliam create ", params);
    setSelectedClaim(params.row);
    setClaimDealerId(params.row.dealerId);
    setClaimAgreementId(params.row.jbwAgreementId);
    setOpenJBWIframeClaimDialog(true);
  };

  useEffect(() => {
    setClaimsList(claimList);
    if (tenant) {
      setClaimsList((oldvlu) => {
        return claimList.filter((cl) => cl.tenantId === tenant);
      });
    }
  }, [tenant, claimList]);

  const QuickSearchToolbar = () => {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  };

  return (
    <>
      <JBWClaimsIframDialog
        dialogOpen={openJBWIframeClaimDialog}
        onClose={handleJBWIframeClaimDialogClose}
        dealerId={claimDealerId}
        agreementId={claimAgreementId}
      />
      {selectedAgreementId && (
        <JBWAgreementsDetailDialog
          jbwAgreementId={selectedAgreementId}
          dialogOpen={openAgreementDetailDialog}
          onClose={handleAgreementDetailDialogClose}
        />
      )}
      {selectedInvoiceId && (
        <InvoiceDetailsDialog
          invoiceId={selectedInvoiceId}
          dialogOpen={openInvoiceDetailDialog}
          onClose={handleInvoiceDetailDialogClose}
        />
      )}
      {showLoading === true ? (
        <Box sx={{ height: "100px" }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      ) : (
        <StyledDataGrid
          rows={claimsList}
          onCellClick={handleCellClick}
          getRowId={(row) => row.id}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          pageSizeOptions={[5]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          // getRowClassName={(params) => {
          //   return params.row.jbwAgreementId === null
          //     ? `super-app-theme--InActive`
          //     : `super-app-theme--Active`;
          // }}
          disableColumnFilter={true}
          disableColumnMenu={true}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: QuickSearchToolbar,
          }}
          sx={{
            "& .muidatagrid-colcelltitle ": {
              display: "block",
              textAlign: "center",
              width: "100%",
            },
          }}
        />
      )}
    </>
  );
};

export default ClaimsGrid;
