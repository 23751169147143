import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AgreementSyncGrid from "../components/AgreementSyncGrid";
import SyncIcon from "@mui/icons-material/Sync";
import { stDataSyncSpCall } from "../services/agreementMatchDataService.js";
import { getDealerRegistrationList } from "../services/dealerRegisterServices.js";
import { AuthContext } from "../AuthContext.jsx";
import axios from "../services/configs/axiosConfig.js";

const AgreementsSyncHome = () => {
  const { group } = useContext(AuthContext);
  const [selectedDealer, setSelectedDealer] = useState(
    JSON.parse(localStorage.getItem("dealer"))
  );
  const [dealers, setDealers] = useState([]);
  const [enableSyncBtn, setEnableSyncBtn] = useState(false);

  const loadDealers = () => {
    getDealerRegistrationList()
      .then((res) => {
        const data = res.data;
        const statusCode = res.status;
        if (statusCode === 200) {
          if (data && data.length > 0) {
            const nonAdminDealers = data.filter(
              (dt) => dt.isActive !== 0 && dt.dealerId !== 0
            );
            setDealers(nonAdminDealers);
            if (nonAdminDealers.length > 0) {
              setSelectedDealer(nonAdminDealers[0]);
            }
          }
        }
      })
      .catch((err) => {
        console.error("Internal server error : ", err);
      });
  };

  useEffect(() => {
    if (group === "jbw-admin") {
      loadDealers();
    }
  }, []);

  const handleDealerChange = (event) => {
    const dealerId = event.target.value;
    console.log("selected dealer", dealerId);
    setSelectedDealer(dealerId);
  };

  const syncDataHandler = async () => {
    const url = `agreementsync/${selectedDealer.dealerId}/spcall`;
    //stDataSyncSpCall(selectedDealer.dealerId)
    try {
      axios
        .get(url)
        .then(
          (res) => {
            if (res.status === 200) {
              console.log("Agreement sync success");
            }
          },
          (err) => {
            console.log("unable to sync agreements service", err);
          }
        )
        .catch((err) => {
          console.error("Internal server error : ", err);
        });
    } catch (error) {
      console.error("Internal server error : ", error);
    }

    setEnableSyncBtn(true);
  };

  const emptyDataListHandle = (data) => {
    setEnableSyncBtn(data);
  };

  return (
    <Box component="main">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={12}>
            <Box
              sx={{
                maxWidth: "100%",
                padding: 2,
                boxShadow: 4,
                borderRadius: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      padding: 1,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h6" component="h6">
                      Agreements Sync <br />
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{ color: "#a8a8a8d4" }}
                      ></Typography>
                    </Typography>
                    {group === "jbw-admin" && selectedDealer && (
                      <Box>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <InputLabel id="dealer-select-small-label">
                            Dealers
                          </InputLabel>
                          <Select
                            labelId="dealer-select-small-label"
                            id="dealer-select-small"
                            value={selectedDealer}
                            label="Dealers"
                            onChange={handleDealerChange}
                          >
                            {dealers.map((ad) => {
                              return (
                                <MenuItem key={ad.id} value={ad}>
                                  {ad.dealerId} - {ad.dealerName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    {enableSyncBtn && (
                      <Box>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          sx={{
                            borderRadius: "50px",
                          }}
                          startIcon={<SyncIcon />}
                          onClick={syncDataHandler}
                        >
                          Sync Data
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Box
                  sx={{
                    maxWidth: "100%",
                    padding: 1,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {selectedDealer && (
                    <AgreementSyncGrid
                      dealer={
                        group === "jbw-admin"
                          ? selectedDealer.dealerId
                          : selectedDealer.id
                      }
                      onEmptyDataList={emptyDataListHandle}
                    />
                  )}
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AgreementsSyncHome;
