import { createContext, useState, useEffect } from "react";
import * as auth from "./services/jbwAuthServices";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dealer, setDealer] = useState(null);
  const [onboarding, setOnboarding] = useState(null);
  const [isOnboarded, setIsOnboarded] = useState(true);
  const [group, setGroup] = useState("");
  const [loginError, setLoginError] = useState("");
  const [mailMessage, setMailMessage] = useState(null);
  const [ctxStEquipments, setCtxStEquipments] = useState([]);

  const getCurrentUser = async () => {
    try {
      const user = await auth.getCurrentUser();
      console.log("Auth context -- current user", user);
      setUser(user);
      currentSession();
      setLoginError("");
    } catch (err) {
      // not logged in
      console.log(err);
      setUser(null);
    }
  };

  const currentSession = async () => {
    try {
      const session = await auth.getSession();
      setSession(session);
      setGroup(session.idToken.payload["cognito:groups"][0]);
    } catch (err) {
      console.log(err);
      setSession(null);
    }
  };

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  const signIn = async (username, password) => {
    //debugger;
    localStorage.setItem("dealer", "");
    setLoginError("");
    try {
      const data = await auth.signIn(username, password);
      if (data) {
        await getCurrentUser();
      }
    } catch (error) {
      console.log("AuthContext signin :", error);
      //throw new Error("User Not found");
      if (error.name && error.name === "UserNotFoundException") {
        console.log("users not found", error.message);
        setLoginError(error.message);
      }
      if (error.name && error.name === "NotAuthorizedException") {
        console.log("users not autorized", error.message);
        setLoginError(error.message);
      }
    }
  };
  const signOut = async () => {
    setUser(null);
    setSession(null);
    setDealer(null);
    setGroup(null);
    setIsLoading(true);
    setOnboarding(null);
    setLoginError("");
    setIsOnboarded(true);
    setMailMessage(null);
    await auth.signOut();
    localStorage.removeItem("dealer");
    localStorage.removeItem("dealerSycState");
    pageReload();
  };
  const pageReload = () => {
    for (var i = 0; i < 4; i++) {
      window.location.reload();
    }
  };

  const authValue = {
    user,
    session,
    isLoading,
    signIn,
    signOut,
    dealer,
    group,
    onboarding,
    isOnboarded,
    setDealer,
    setOnboarding,
    loginError,
    setIsOnboarded,
    mailMessage,
    setMailMessage,
    ctxStEquipments,
    setCtxStEquipments,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
