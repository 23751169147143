import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IconTextField from "./IconTextField";
import { AccountCircle, EmailOutlined } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import { verifyDealer } from "../services/dealerServices";
import {
  verifyDealerRegistration,
  disableUser,
} from "../services/dealerRegisterServices";
import { userSignup } from "../services/signupService";
import JbwSwitch from "./JbwSwitch";

const UserRegistrationDialog = (props) => {
  const { onClose, open, isEdit = false, userData } = props;
  const [titelText, setTitelText] = useState("Dealer");
  const [roleValue, setRoleValue] = useState("jbw-dealer");
  const [fieldAf, setFieldAf] = useState(true);
  const [dealerId, setDealerId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [fullNameErr, setFullNameErr] = useState("");
  const [companyNameErr, setCompanyNameErr] = useState("");
  const [dealerIdErr, setDealerIdErr] = useState("");
  const [dealerErr, setDealerErr] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(true);
  const [disableDealerId, setDisableDealerId] = useState(false);
  const [disableRadio, setDisableRadio] = useState(false);
  const [hideisActive, setHideIsActive] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState(false);
  const [disableisActive, setDisableIsActive] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [id, setId] = useState("");
  const [disableCancel, setDisableCancel] = useState(false);

  useEffect(() => {
    if (isEdit && userData) {
      setDisableDealerId(true);
      setDisableRadio(true);
      setHideIsActive(true);
      setDealerErr("");
      setId(userData.id);
      setIsActiveChecked(userData.isActive === 1 ? true : false);
      setDealerId(userData.dealerId);
      setCompanyName(userData.companyName);
      setFullName(userData.fullName);
      setEmail(userData.email);
      if (userData.roleValue === "Dealer") {
        setRoleValue("jbw-dealer");
        setFieldAf(true);
      } else {
        setRoleValue("jbw-admin");
        setFieldAf(false);
      }
      setTitelText(userData.roleValue);
      // setIsFormSubmitted(true);
    }
  }, [isEdit, userData]);
  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "fullName") {
      setFullName(value);
    }
    if (formField === "companyName") {
      setCompanyName(value);
    }
    if (formField === "dealerId") {
      setDealerId(value);
    }
  };

  const dealerIdValidation = () => {
    return new Promise((resolve, reject) => {
      if (dealerId === "") {
        setDealerIdErr("Dealer ID required");
        resolve({ dealerId: "Dealer Id required" });
      } else {
        resolve({ dealerId: "" });
      }
      reject("");
    });
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (roleValue === "jbw-dealer" && companyName === "" && dealerId === "") {
        setEmailErr("Email is Required");
        setFullNameErr("Full Name is Required");
        setCompanyNameErr("Company Name is Required");
        setDealerIdErr("Dealer ID is Required");
        resolve({
          email: "Email is Required",
          fullName: "Full Name is Required",
          companyName: "Company Name is Required",
          dealerId: "Dealer ID is Required",
        });
      } else if (roleValue === "jbw-admin" && email === "" && fullName === "") {
        setEmailErr("Email is Required");
        setFullNameErr("Full Name is Required");
        resolve({
          email: "Email is Required",
          password: "Password is required",
          fullName: "Full Name is Required",
        });
      } else if (email === "") {
        setEmailErr("Email is Required");
        resolve({
          email: "Email is Required",
          fullName: "",
          companyName: "",
          dealerId: "",
        });
      } else if (fullName === "") {
        setFullNameErr("Full Name is required");
        resolve({
          email: "",
          fullName: "Full Name is required",
          companyName: "",
          dealerId: "",
        });
      } else if (roleValue === "jbw-dealer" && dealerId === "") {
        setDealerIdErr("Dealer ID is required");
        resolve({
          email: "",
          fullName: "",
          companyName: "",
          dealerId: "Dealer ID is required",
        });
      } else if (roleValue === "jbw-dealer" && companyName === "") {
        setCompanyNameErr("Company Name is required");
        setDealerErr("Please valid Dealer ID to Populate Company Name");
        resolve({
          email: "",
          fullName: "",
          companyName: "Company Name is required",
          dealerId: "",
        });
      } else {
        resolve({
          email: "",
          fullName: "",
          companyName: "",
          dealerId: "",
        });
      }
      reject("");
    });
  };

  const handleVerifyDealer = (dealerId) => {
    if (isEdit) {
      return;
    }
    setCompanyName("");
    setEmailErr("");
    setFullNameErr("");
    setCompanyNameErr("");
    setDealerIdErr("");
    setDealerErr("");
    setDisableDealerId(true);
    setDisableCancel(true);
    setDisableRadio(true);
    dealerIdValidation()
      .then(
        (res) => {
          console.log(res);
          if (res.dealerId === "") {
            verifyDealerRegistration(dealerId)
              .then(
                (dr) => {
                  let da = dr.data;
                  if (da && da.length > 0) {
                    setDealerIdErr(
                      "Dealer already registrated.You can login using existing Credentials"
                    );
                    setDisableDealerId(false);
                    setDisableCancel(false);
                    setDisableRadio(false);
                  } else {
                    verifyDealer(dealerId)
                      .then(
                        (res) => {
                          let data = res.data;
                          if (data && data.length > 0) {
                            // if (data[0].registered === 1) {
                            //   setDealerIdErr(
                            //     "Dealer already registrated.You can login using existing Credentials"
                            //   );

                            //   setDisableDealerId(false);
                            //   setDisableCancel(false);
                            //   setDisableRadio(false);
                            // } else {
                            //   setCompanyName(data[0].dealerName);
                            //   setIsFormSubmitted(false);
                            //   setDisableDealerId(false);
                            //   setDisableCancel(false);
                            //   setDisableRadio(false);
                            // }
                            setCompanyName(data[0].dealerName);
                            setIsFormSubmitted(false);
                            setDisableDealerId(false);
                            setDisableCancel(false);
                            setDisableRadio(false);
                          } else {
                            setIsFormSubmitted(true);
                            setDisableDealerId(false);
                            setDisableCancel(false);
                            setDisableRadio(false);
                            setDealerIdErr(
                              "No dealer Found with give Dealer ID, Please Login to JB Warrenties check the Dealer Id"
                            );
                          }
                        },
                        (err) => {
                          console.log(err);
                          setDealerErr("Error in verifying the dealer");
                          setDisableDealerId(false);
                          setDisableCancel(false);
                          setDisableRadio(false);
                        }
                      )
                      .catch((err) => {
                        console.log(err);
                        setDealerErr("Ineternal error try again");
                        setDisableDealerId(false);
                        setDisableCancel(false);
                        setDisableRadio(false);
                      });
                  }
                },
                (err) => {
                  console.log(err);
                  setDealerErr("Unable to verify dealer registration");
                  setDisableDealerId(false);
                  setDisableCancel(false);
                  setDisableRadio(false);
                }
              )
              .catch((err) => {
                console.log(err);
                setDisableDealerId(false);
                setDisableCancel(false);
                setDisableRadio(false);
                setDealerErr("Ineternal error try again");
              });
          } else {
            setDisableDealerId(false);
            setDisableCancel(false);
            setDisableRadio(false);
          }
        },
        (err) => {
          console.log(err);
          setDisableDealerId(false);
          setDisableCancel(false);
          setDisableRadio(false);
        }
      )
      .catch((err) => {
        setDisableDealerId(false);
        setDisableCancel(false);
        console.log(err);
      });
  };

  const handleRoleChange = (event) => {
    resetForm();
    const role = event.target.value;
    if (role === "jbw-dealer") {
      setTitelText("Dealer");
      setIsFormSubmitted(true);
      setFieldAf(true);
    } else {
      setTitelText("Admin");
      setIsFormSubmitted(false);
      setFieldAf(false);
    }
    setRoleValue(role);
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    resetForm();
    onClose("closed");
  };

  const handleisActiveChecked = (e) => {
    setIsActiveChecked(e);
    setDisableUpdate(false);
    console.log("active status changed ->", e);
  };

  const handleRegister = () => {
    console.log("register called");
    // setCompanyName("");
    setEmailErr("");
    setFullNameErr("");
    setCompanyNameErr("");
    setDealerIdErr("");
    setDealerErr("");
    setIsFormSubmitted(true);
    setDisableCancel(true);
    setDisableRadio(true);
    setDisableIsActive(true);
    if (isEdit) {
      setDisableUpdate(true);
      const reqData = {
        email: email,
        id: id,
        dealerId: dealerId,
        isActive: isActiveChecked,
      };
      disableUser(reqData)
        .then(
          (res) => {
            console.log(res.data);
            const data = res.data;
            if (res.status === 200) {
              setIsFormSubmitted(false);
              setDisableDealerId(false);
              resetForm();
              onClose("userCreated");
            } else if (res.status === 500) {
              const message = data;
              setIsActiveChecked(userData.isActive === 1 ? true : false);
              setDisableUpdate(true);
              setDisableCancel(false);
              setDisableIsActive(false);
              console.log("Error disabling user :", message);
              setDealerErr(data);
            } else {
              setIsActiveChecked(userData.isActive === 1 ? true : false);
              setDisableUpdate(true);
              setDisableCancel(false);
              setDisableIsActive(false);
              setDealerErr("Server time out please try again");
            }
          },
          (err) => {
            setIsActiveChecked(userData.isActive === 1 ? true : false);
            setDisableUpdate(true);
            setDisableCancel(false);
            setDisableIsActive(false);
            console.log("Error disabling user :", err);
            setDealerErr(`Unable to disable ${titelText}  User`);
          }
        )
        .catch((err) => {
          setIsActiveChecked(userData.isActive === 1 ? true : false);
          setDisableUpdate(true);
          setDisableCancel(false);
          setDisableIsActive(false);
          console.error("Error calling disable user service", err);
          setDealerErr("Internal server error");
        });
    } else {
      console.log("new user creation");
      validation()
        .then(
          (res) => {
            console.log("new registration res", res);
            if (
              res.email === "" &&
              res.fullName === "" &&
              res.companyName === "" &&
              res.dealerId === ""
            ) {
              console.log("new registration validation passed");
              const userData = {
                email: email,
                fullname: fullName,
                profile: dealerId,
                companyname: companyName,
                group: roleValue,
              };
              userSignup(userData)
                .then(
                  (res) => {
                    console.log("UserSignUp response :", res);
                    const statusCode = res.status;
                    console.log("usersignup response data ::", res.data);
                    if (statusCode !== 200) {
                      setIsFormSubmitted(false);
                      setDisableDealerId(false);
                      setDisableCancel(false);
                      setDisableRadio(false);
                      setDisableIsActive(false);
                      setDealerErr(`${JSON.parse(res.data).message}`);
                      return;
                    }
                    console.log("usersignup response", res);
                    setIsFormSubmitted(false);
                    setDisableDealerId(false);
                    resetForm();
                    onClose("userCreated");
                  },
                  (err) => {
                    console.log("Error registring user :", err);
                    setIsFormSubmitted(false);
                    setDisableDealerId(false);
                    setDisableCancel(false);
                    setDisableRadio(false);
                    setDisableIsActive(false);
                    setDealerErr(`Unable to register ${titelText}  User`);
                  }
                )
                .catch((err) => {
                  console.error("Error calling sign up service", err);
                  setIsFormSubmitted(false);
                  setDisableDealerId(false);
                  setDisableCancel(false);
                  setDisableRadio(false);
                  setDisableIsActive(false);
                  setDealerErr("Internal server error");
                });
            } else {
              setIsFormSubmitted(false);
              setDisableDealerId(false);
              setDisableRadio(false);
              setDisableCancel(false);
              setDisableIsActive(false);
            }
          },
          (err) => {
            console.log(err);
            setIsFormSubmitted(false);
            setDisableDealerId(false);
            setDisableRadio(false);
            setDisableCancel(false);
            setDisableIsActive(false);
          }
        )
        .catch((err) => {
          console.log(err);
          setIsFormSubmitted(false);
          setDisableDealerId(false);
          setDisableRadio(false);
          setDisableIsActive(false);
          setDisableCancel(false);
        });
    }
  };

  const resetForm = () => {
    setRoleValue("jbw-dealer");
    setEmailErr("");
    setFullNameErr("");
    setCompanyNameErr("");
    setDealerIdErr("");
    setEmail("");
    setFullName("");
    setCompanyName("");
    setDealerId("");
    setDisableCancel(false);
    setDisableRadio(false);
    setDealerErr("");
    setDisableDealerId(false);
    setIsActiveChecked(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>{titelText} Registration</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12} sm={6} md={6}>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiFormControl-root": {
                    display: "inline-block",
                    float: "right",
                  },
                }}
              >
                <FormLabel id="role-radio-group-label" sx={{ maxWidth: 70 }}>
                  Role
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="role-radio-group-label"
                  name="row-radio-buttons-group"
                  value={roleValue}
                  onChange={handleRoleChange}
                  sx={{ maxWidth: 200 }}
                >
                  <FormControlLabel
                    value="jbw-dealer"
                    control={<Radio />}
                    label="Dealer"
                    disabled={disableRadio ? true : false}
                  />
                  <FormControlLabel
                    value="jbw-admin"
                    control={<Radio />}
                    label="Admin"
                    disabled={disableRadio ? true : false}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {roleValue === "jbw-dealer" && (
              <>
                <Grid item xs={12} sm={6} md={6}>
                  <IconTextField
                    iconStart={
                      <BadgeIcon sx={{ color: "#bdbdbd", fontSize: 20 }} />
                    }
                    autoFocus={fieldAf}
                    margin="normal"
                    id="dealerId"
                    label="Dealer ID"
                    type="text"
                    fullWidth
                    value={dealerId}
                    onChange={(e) =>
                      formInputChange("dealerId", e.target.value)
                    }
                    onBlur={(e) => handleVerifyDealer(e.target.value)}
                    error={dealerIdErr ? true : false}
                    helperText={dealerIdErr}
                    size="small"
                    disabled={disableDealerId ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <IconTextField
                    iconStart={
                      <BusinessIcon sx={{ color: "#bdbdbd", fontSize: 20 }} />
                    }
                    margin="normal"
                    id="companyName"
                    label="Company Name"
                    type="text"
                    fullWidth
                    value={companyName}
                    onChange={(e) =>
                      formInputChange("companyName", e.target.value)
                    }
                    disabled={true}
                    error={companyNameErr ? true : false}
                    helperText={companyNameErr}
                    size="small"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={6} md={6}>
              <IconTextField
                iconStart={
                  <AccountCircle sx={{ color: "#bdbdbd", fontSize: 20 }} />
                }
                autoFocus={!fieldAf}
                margin="normal"
                id="fullName"
                label="Full Name"
                type="fullName"
                fullWidth
                value={fullName}
                onChange={(e) => formInputChange("fullName", e.target.value)}
                error={fullNameErr ? true : false}
                helperText={fullNameErr}
                size="small"
                disabled={isFormSubmitted ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <IconTextField
                iconStart={
                  <EmailOutlined sx={{ color: "#bdbdbd", fontSize: 20 }} />
                }
                margin="normal"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => formInputChange("email", e.target.value)}
                error={emailErr ? true : false}
                helperText={emailErr}
                size="small"
                disabled={isFormSubmitted ? true : false}
              />
            </Grid>
            {hideisActive && (
              <Grid item xs={12} sm={6} md={6}>
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" component="div" sx={{ mr: 5 }}>
                    Active Status :
                  </Typography>
                  <JbwSwitch
                    isChecked={handleisActiveChecked}
                    setChecked={isActiveChecked}
                    id={id.toString()}
                    disabled={disableisActive ? true : false}
                  />
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="body"
                sx={{ color: "#f44336", fontSize: "10px" }}
              >
                {dealerErr}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={disableCancel ? true : false}>
          Cancel
        </Button>
        {isEdit ? (
          <Button
            onClick={handleRegister}
            disabled={disableUpdate ? true : false}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={handleRegister}
            disabled={isFormSubmitted ? true : false}
          >
            Register
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UserRegistrationDialog;
