import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import AgreementsGrid from "./AgreementsGrid";
import { Grid } from "@mui/material";
import { getAgreementList } from "../services/agreementServices";
import AgmEquiplmentDialog from "./AgmEquiplmentDialog";
import AgmConfirmEquimentsDialog from "./AgmConfirmEquimentsDialog";
import NewAgreementDialog from "./NewAgreementDialog";
import DuiplicateAgreementCreateDialog from "./DuiplicateAgreementCreateDialog";
import AgreementEquimentDialog from "./AgreementEquimentDialog";

const AgreementList = ({
  dealer,
  showDealer = false,
  displayType,
  dealerId,
  filterAgreementId,
  tenant,
}) => {
  console.log("AgreementList displayType : ", displayType);
  console.log(
    `AgreementList displayType dealerid:${dealerId}, filteredAgreementId : ${filterAgreementId}`
  );
  const { user, setCtxStEquipments } = useContext(AuthContext);
  const [agreementList, setAgreementList] = useState([]);
  const [selectedAgreementId, setSelectedAgreementId] = useState(null);
  const [syncAgreementId, setSyncAgreementId] = useState(null);
  const [optype, setOptype] = useState("");
  const [selectedDealer, setSelectedDealer] = useState(dealer);
  const [disType, setDisType] = useState(displayType);
  const [selectedDealerId, setSelectedDealerId] = useState(dealerId);
  const [selectedProductCode, setSelectedProductCode] = useState(null);
  const [selectedPoNumber, setSelectedPoNumber] = useState(null);
  const [openAgmEquiDialog, setOpenAgmEquiDialog] = useState(false);
  const [openAgmDetailsDialog, setOpenAgmDetailsDialog] = useState(false);
  const [openEquSyncDialog, setOpenEquSyncDialog] = useState(false);
  const [agreementListLoading, setAgreementListLoading] = useState(false);
  const [openNewAgreementDialog, setOpenNewAgreementDialog] = useState(false);

  //console.log("Agreement list dealer data:", dealer);
  //console.log("Agreement list group :", group);
  // useEffect(() => {
  //   setSelectedDealer(dealer);
  //   //setDisType(displayType);
  // }, [dealer]);
  useEffect(() => {
    if (selectedDealerId > 0) {
      loadDealerList(selectedDealerId);
    }
  }, [dealerId]);

  const loadDealerList = (_dealerId) => {
    setAgreementList([]);
    setAgreementListLoading(true);
    getAgreementList(_dealerId)
      .then(
        (res) => {
          const data = res.data.body;
          console.log("Adgreement List :", data);
          const statusCode = res.status;
          if (statusCode === 200 && data.length > 0) {
            if (filterAgreementId && filterAgreementId > 0) {
              const tempData = data.filter((d) => d.id === filterAgreementId);
              setAgreementList(tempData);
            } else {
              setAgreementList(data);
            }
          }
          setAgreementListLoading(false);
        },
        (err) => {
          console.log("Unable to load data from agreement service", err);
          setAgreementListLoading(false);
        }
      )
      .catch((err) => {
        console.error("Get Agreements List Internal Server error", err);
        setAgreementListLoading(false);
      });
  };

  useEffect(() => {
    //const dealerId = selectedDealer ? selectedDealer.id : 0;
    if (disType === "admin") {
      console.log("loading agreement list from admin user");
      loadDealerList(0);
    } else {
      console.log("display type :", disType);
      if (selectedDealer) {
        loadDealerList(selectedDealer.dealerId);
      }
    }
  }, [selectedDealer, disType]);

  const handleOpenEquiDialog = (param, type) => {
    console.log("AgreementList selected agreement :", param);
    setCtxStEquipments([]);
    setSelectedAgreementId({
      ...param,
      address: param.location,
      customer: param.customerName,
    });
    setSelectedProductCode(param.productCode);
    setSelectedPoNumber(param.poNumber ? param.poNumber : param.invoiceId);
    setOptype(type);
    setOpenAgmEquiDialog(true);
  };

  const handleOpenDuplicateAgreementDialog = (param) => {
    console.log("AgreementDetails selected agreement :", param);
    if (param.ctype && param.ctype === "newaggclose") {
      handleNewAgreementDialogClose();
    }
    setSelectedAgreementId(param.id);
    setOpenAgmDetailsDialog(true);
    setCtxStEquipments([]);
  };

  const handleAgmEquiDialogClose = (data) => {
    console.log("close called :", data);
    if (data === "success") {
      console.log("reloading dealers");
      const dealerId = selectedDealer ? selectedDealer.dealerId : 0;
      console.log("reloading dealers:", dealerId);
      loadDealerList(dealerId);
    }
    setOpenAgmEquiDialog(false);
    setOptype("");
    setSelectedPoNumber(null);
    setSelectedProductCode(null);
    setSelectedAgreementId(null);
    setCtxStEquipments([]);
  };

  const handleOpenEquSyncDialog = (param) => {
    console.log("Sync Equipment agreement :", param);

    setSyncAgreementId({
      ...param,
      address: param.location,
      customer: param.customerName,
    });
    setSelectedProductCode(param.productCode);
    setSelectedPoNumber(param.poNumber);
    setOpenEquSyncDialog(true);
  };

  const handleEquSyncDialogClose = (data) => {
    console.log("sync dialog close  called :", data);
    if (data === "success") {
      console.log("reloading dealers");
      const dealerId = selectedDealer ? selectedDealer.dealerId : 0;
      console.log("reloading dealers:", dealerId);
      loadDealerList(dealerId);
    }
    setSyncAgreementId(null);
    setCtxStEquipments([]);
    setOpenEquSyncDialog(false);
  };

  const handleCloseAgmDetailsDialog = (data) => {
    console.log("close called :", data);
    if (data === "success") {
      console.log("reloading dealers");
      const dealerId = selectedDealer ? selectedDealer.dealerId : 0;
      console.log("reloading dealers:", dealerId);
      loadDealerList(dealerId);
    }
    setSelectedAgreementId("");
    setOpenAgmDetailsDialog(false);
  };

  const handleEquipmentEdit = (data) => {
    setOpenEquSyncDialog(false);
    setSyncAgreementId("");
    setSelectedAgreementId(data);
    setOptype("update");
    setOpenAgmEquiDialog(true);
  };

  const handleNewAgreementDialogClose = (data) => {
    console.log("close called :", data);
    if (data === "success") {
      console.log("reloading dealers");
      const dealerId = selectedDealer ? selectedDealer.dealerId : 0;
      console.log("reloading dealers:", dealerId);
      loadDealerList(dealerId);
    }
    setOpenNewAgreementDialog(false);
  };

  const handleOpenNewAgreementDialog = () => {
    setCtxStEquipments([]);
    setOpenNewAgreementDialog(true);
  };

  return (
    <>
      {/* {Duplicate Agreement} */}
      {openAgmDetailsDialog && (
        <DuiplicateAgreementCreateDialog
          openAgmDetailsDialog={openAgmDetailsDialog}
          agreementId={selectedAgreementId}
          onCloseAgmDetailsDialog={handleCloseAgmDetailsDialog}
        />
      )}
      {/* {openAgmEquiDialog && (
        <AgmEquiplmentDialog
          onClose={handleAgmEquiDialogClose}
          open={openAgmEquiDialog}
          agreementId={selectedAgreementId}
          productCode={selectedProductCode}
          poNumber={selectedPoNumber}
          optype={optype}
          userName={user.name}
        />
      )} */}
      {/* {New and Edit Equipment} */}
      {openAgmEquiDialog && (
        <AgreementEquimentDialog
          onClose={handleAgmEquiDialogClose}
          open={openAgmEquiDialog}
          agreement={selectedAgreementId}
          productCode={selectedProductCode}
          poNumber={selectedPoNumber}
          optype={optype}
        />
      )}
      {/* {Sync Equipment} */}
      {openEquSyncDialog && (
        <AgmConfirmEquimentsDialog
          onClose={handleEquSyncDialogClose}
          open={openEquSyncDialog}
          agreementId={syncAgreementId}
          onEdit={handleEquipmentEdit}
        />
      )}

      {/* {New Agreement} */}

      {openNewAgreementDialog && (
        <NewAgreementDialog
          open={openNewAgreementDialog}
          onClose={handleNewAgreementDialogClose}
          dealerId={dealer?.dealerId}
          onCreateDuplicateAgreement={handleOpenDuplicateAgreementDialog}
        />
      )}

      <Grid container>
        <Grid item xs={12}>
          <AgreementsGrid
            agreementList={agreementList}
            showDealer={showDealer}
            openEquimentDialog={handleOpenEquiDialog}
            openEquConfirmDialog={handleOpenEquSyncDialog}
            openDuplicateAgreementDialog={handleOpenDuplicateAgreementDialog}
            showLoading={agreementListLoading}
            onNewAgreement={handleOpenNewAgreementDialog}
            tenant={tenant}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AgreementList;
