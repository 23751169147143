import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import React, { useState } from "react";
import { envConfig } from "../services/configs/envConfig";

import { updateReleaseNotesStatus } from "../services/dealerRegisterServices";
import { red } from "@mui/material/colors";

const JBWReleasaNotesDialog = () => {
  const dealer = JSON.parse(localStorage.getItem("dealer"));
  const [openDialog, setOpenDialog] = useState(
    dealer.releaseNotesViewed === 0 ? true : false
  );
  const [showPopup, setShowPopup] = useState(false);
  const [seconds, setSeconds] = React.useState(15);

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      handleClose();
    }
  });
  const handleClose = () => {
    setOpenDialog(false);
    if (showPopup) {
      updateReleaseNote();
    }
  };
  const handleDownloadReleaseNotes = () => {
    const fileUrl = envConfig.RNUrl;
    const fileName = fileUrl.split("/").pop();
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    updateReleaseNote();
  };

  const updateReleaseNote = () => {
    console.log("JBWReleaseNotesDialog: ", dealer);
    updateReleaseNotesStatus(dealer.registerId).then(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  return (
    <Dialog
      onClose={handleClose}
      open={openDialog}
      PaperProps={{
        component: "div",
      }}
      fullWidth={true}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <b>JB360 {envConfig.RNMonth} Release Notes</b>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers sx={{ height: "180px" }}>
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="subtitle1"
                align="center"
                sx={{ fontSize: "17px" }}
              >
                We have released some exciting new features to enhance your
                JB360 experience.
                {/* <IconButton
                  color="secondary"
                  variant="outlined"
                  aria-label="Download Release Notes"
                  sx={{ marginTop: "0px", fontSize: "17px" }}
                  onClick={handleDownloadReleaseNotes}
                >
                  click here
                  <NewspaperOutlinedIcon sx={{ fontSize: "17px", ml: "3px" }} />
                </IconButton> */}
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{ mr: "3px" }}
                  aria-label="Download Release Notes"
                  onClick={handleDownloadReleaseNotes}
                  endIcon={<NewspaperOutlinedIcon size="small" />}
                >
                  click here
                </Button>
                to download our release notes.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="warning"
                component="p"
                align="center"
                sx={{ fontSize: "12px" }}
              >
                This popup will auto close in {seconds} seconds
              </Typography>
            </Grid>
          </Grid>
        </>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setShowPopup((prev) => !prev);
                    }}
                  />
                }
                label="Do not show this popup on next login"
              />
            </FormGroup>
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item>
            <Button onClick={handleClose}>Close</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default JBWReleasaNotesDialog;
