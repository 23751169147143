import { createTheme } from "@mui/material";
import { createPalette } from "./create-palette";
import { createComponents } from "./create-components";
import { createShadows } from "./create-shadows";
import { createTypography } from "./create-typography";
import { amber, blue, green, grey, red } from "@mui/material/colors";

// const createTheme = () => {
//   const palette = createPalette();
//   const components = createComponents({ palette });
//   const shadows = createShadows();
//   const typography = createTypography();
//   return createMuiTheme({
//     palette: {
//       ...palette,
//       primary: {
//         light: blue[300],
//         main: blue[500],
//         dark: blue[700],
//         darker: blue[900],
//       },
//       secondary: {
//         light: amber[300],
//         main: amber[500],
//         dark: amber[700],
//         darker: amber[900],
//       },
//     },
//     typography: {
//       ...typography,
//       button: {
//         textTransform: "none",
//       },
//     },
//     breakpoints: {
//       values: {
//         xs: 0,
//         sm: 600,
//         md: 900,
//         lg: 1200,
//         xl: 1440,
//       },
//     },
//     components,
//     shadows,
//     shape: {
//       borderRadius: 8,
//     },
//   });
// };
// export default createTheme;
const appTheme = createTheme({
  palette: {
    primary: {
      slight: blue[50],
      mlight: blue[100],
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      darker: blue[900],
    },
    secondary: {
      slight: amber[50],
      mlight: amber[100],
      light: amber[300],
      main: amber[500],
      dark: amber[700],
      darker: amber[900],
    },
    warning: {
      slight: red[50],
      mlight: red[100],
      light: red[300],
      main: red[500],
      dark: red[700],
      darker: red[900],
    },
    mousehovergrey: {
      main: grey[400],
      hover: grey[300],
    },
    green: {
      slight: green[50],
      mlight: green[100],
      light: green[300],
      main: green[500],
      dark: green[700],
      darker: green[900],
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    warning: {
      color: red[500],
    },
    info: {
      color: amber[500],
    },
    success: {
      color: green[500],
    },
    caption: {
      color: blue[500],
    },
  },
});

export default appTheme;
