import {
  Avatar,
  IconButton,
  styled,
  Box,
  Skeleton,
  Typography,
  Link,
  Tooltip,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomNoRowsOverlay from "./gridNoRowOverLay";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentLateTwoToneIcon from "@mui/icons-material/AssignmentLateTwoTone";
import AssignmentTurnedInTwoToneIcon from "@mui/icons-material/AssignmentTurnedInTwoTone";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { green, amber } from "@mui/material/colors";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { envConfig } from "../services/configs/envConfig";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Active": {
    backgroundColor: "#71d68e",
    "&:hover": {
      backgroundColor: "#4ECC72",
    },
  },
  "& .super-app-theme--InActive": {
    backgroundColor: "#e4c553",
    "&:hover": {
      backgroundColor: "#DEB729",
    },
  },
  "& .super-app-theme--dup": {
    backgroundColor: "#FFF8EB",
    "&:hover": {
      backgroundColor: "#FFD485",
    },
  },
}));

const AgreementsGrid = ({
  agreementList,
  showDealer = false,
  openEquimentDialog,
  openEquConfirmDialog,
  openDuplicateAgreementDialog,
  onNewAgreement,
  showLoading = false,
  tenant,
}) => {
  const columns = [
    {
      field: "id",
      headerName: "",
      width: 45,
      sortable: false,
      renderCell: (params) => {
        const bgColor =
          params.row.jbwAgreementId === null || params.row.jbwAgreementId === ""
            ? "#DEB729"
            : "#4ECC72";
        const iconDisaply =
          params.row.jbwAgreementId === null ||
          params.row.jbwAgreementId === "" ? (
            <AssignmentLateTwoToneIcon />
          ) : (
            <AssignmentTurnedInTwoToneIcon />
          );
        return (
          <Tooltip title={`JB360# :${params.row.id}`}>
            <Avatar sx={{ bgcolor: bgColor, m: 1 }}>{iconDisaply}</Avatar>
          </Tooltip>
        );
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jobNumber",
      headerName: "Job ID",
      width: showDealer ? 80 : 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "poNumber",
      headerName: "Invoice #",
      width: showDealer ? 100 : 110,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const poNumber = params.row.poNumber;
        const invoiceId = params.row.invoiceId;
        return (
          <Typography variant="string" component="span">
            <Link className="table-cell-trucate" underline="always">
              {poNumber ? poNumber : invoiceId}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: showDealer ? 140 : 160,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "location",
      headerName: "Location",
      width: showDealer ? 200 : 210,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        const location = param.row.location;
        const invoiceId = param.row.invoiceId;
        const jbwAgreementId = param.row.jbwAgreementId;
        const tip = jbwAgreementId ? (
          <div style={{ whiteSpace: "pre-line" }}>
            Agreement: {jbwAgreementId}
          </div>
        ) : (
          <div style={{ whiteSpace: "pre-line" }}>Invoice#: {invoiceId}</div>
        );

        return (
          <>
            {location ? (
              <>
                <Typography
                  variant="subtitl1"
                  component="span"
                  sx={{
                    wordWrap: "break-word",
                  }}
                  whiteSpace="normal"
                >
                  {location}.
                  {param.row.parentAgreementId ? (
                    <Tooltip title={tip}>
                      <InfoRoundedIcon
                        style={{ fill: "#ffc400" }}
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Typography>
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "productCode",
      headerName: "Plan Id",
      width: showDealer ? 120 : 140,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwAgreementId",
      headerName: "JBW Agreement Id",
      width: 140,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comments",
      headerName: "Comments",
      width: showDealer ? 180 : 200,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        return handleComment(param);
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      cellClassName: "actions",
      width: 80,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        //console.log("params", params.row);
        const missingEqu = params.row.missingEquipment;
        const readyForJbw = params.row.isReadyForJBW;
        const jbqAId = params.row.jbwAgreementId;
        let cellItems = [];
        //isreadyfor jwd = 0
        if (
          missingEqu === 0 &&
          (jbqAId === null || jbqAId === "") &&
          readyForJbw === 0
        ) {
          cellItems.push(
            <GridActionsCellItem
              icon={<EditIcon fontSize="medium" />}
              label="Edit"
              className="textPrimary"
              onClick={handleEdit(params, "update")}
              color="secondary"
              title="Edit Equipment"
            />
          );
          cellItems.push(
            <GridActionsCellItem
              icon={<ContentCopyIcon fontSize="medium" />}
              label="Duplicate Agreement"
              className="textPrimary"
              onClick={handleDuplicateAgreement(params)}
              color="amber"
              sx={{ color: amber[500] }}
              title="Duplicate Agreement"
            />
          );
        }
        if (missingEqu === 1 && readyForJbw === 0) {
          cellItems.push(
            <GridActionsCellItem
              icon={<AddToQueueIcon fontSize="medium" />}
              label="Add"
              className="textPrimary"
              onClick={handleEdit(params, "create")}
              color="primary"
              title="Add Equipment"
            />
          );
        }
        if (readyForJbw === 1 && (jbqAId === null || jbqAId === "")) {
          cellItems.push(
            <GridActionsCellItem
              icon={<PostAddIcon fontSize="medium" />}
              label="Submit Agreement"
              className="textPrimary"
              onClick={handleSubmitAgreement(params)}
              color="green"
              sx={{ color: green[500] }}
              title="Submit Agreement"
            />
          );
          cellItems.push(
            <GridActionsCellItem
              icon={<ContentCopyIcon fontSize="medium" />}
              label="Duplicate Agreement"
              className="textPrimary"
              onClick={handleDuplicateAgreement(params)}
              color="amber"
              sx={{ color: amber[500] }}
              title="Duplicate Agreement"
            />
          );
        }

        return cellItems;
      },
      sortable: false,
    },
  ];

  if (showDealer) {
    columns.splice(1, 0, {
      field: "dealerId",
      headerName: "Dealer ID",
      width: 90,
      sortable: false,
      headerAlign: "center",
      align: "center",
    });
  }
  const handleEdit = (param, optype) => () => {
    console.log("Edit clicked ", param.row);
    openEquimentDialog(param.row, optype);
  };

  const handleSubmitAgreement = (param) => () => {
    console.log("Submit Agreement clicked ", param.row);
    openEquConfirmDialog(param.row);
  };

  const handleDuplicateAgreement = (param) => () => {
    console.log("Duplicate Agreement clicked ", param.row);
    openDuplicateAgreementDialog(param.row);
  };

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const handleComment = (param) => {
    //console.log("Agreement grid handle comment:", param.value);
    if (param.value === "") return;
    if (Boolean(isValidUrl(param.value)))
      return (
        <IconButton
          aria-label="jbandassociates.biz"
          onClick={() => window.open(param.value)}
          color="warning"
        >
          <PictureAsPdfIcon fontSize="medium" />
        </IconButton>
      );
    return param.value;
  };

  const [agreementsList, setAgreementsList] = useState([]);

  useEffect(() => {
    setAgreementsList(agreementList);
    if (tenant) {
      setAgreementsList((oldvle) => {
        return agreementList.filter((al) => al.tenantId === tenant);
      });
    }
  }, [tenant, agreementList]);

  const handleCellClick = (params) => {
    console.log("cell click event :", params);
    const columnName = params.field;
    const cellValue = params.value;
    console.log(`Selected column : ${columnName} and cell value ${cellValue}`);
    if (columnName === "poNumber") {
      const invoiceUrl = `${envConfig.STUrl}#/EditInvoice/${params.row.invoiceId}`;
      console.log("invoice st url :", invoiceUrl);
      window.open(invoiceUrl, "_blank");
    }
  };

  const QuickSearchToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <Box sx={{ flexGrow: 1 }} />
        {!showDealer && (
          <Button
            variant="contained"
            color="secondary"
            // size="small"
            //disabled={!approveState.length}
            sx={{ borderRadius: "50px" }}
            onClick={handleNewAgreement}
          >
            New Agreement
          </Button>
        )}
      </GridToolbarContainer>
    );
  };

  const handleNewAgreement = () => {
    console.log("New Agreement clicked");
    onNewAgreement();
  };

  return (
    <>
      {showLoading === true ? (
        <Box sx={{ height: "100px" }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      ) : (
        <StyledDataGrid
          rows={agreementsList}
          getRowId={(row) => row.id}
          columns={columns}
          onCellClick={handleCellClick}
          autoHeight
          getRowHeight={() => "auto"}
          pageSizeOptions={[5]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          // getRowClassName={(params) => {
          //   return params.row.parentAgreementId === null
          //     ? ""
          //     : `super-app-theme--dup`;
          // }}
          disableColumnFilter={true}
          disableColumnMenu={true}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: QuickSearchToolbar,
          }}
          sx={{
            "& .muidatagrid-colcelltitle ": {
              display: "block",
              textAlign: "center",
              width: "100%",
            },
          }}
        />
      )}
    </>
  );
};

export default AgreementsGrid;
