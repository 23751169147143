import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AgreementInfo from "./AgreementInfo";
import EquipmentsTable from "./EquipmentsTable";
import { AuthContext } from "../AuthContext";
import {
  addAgreementEquipments,
  getAgreementEquipmentsList,
} from "../services/agreementServices";
import TblProductsSearch from "./TblProductsSearch";
import GradientCircularProgress from "./GradientCircularProgress";

const AgreementEquimentDialog = ({
  agreement,
  open,
  onClose,
  optype,
  productCode,
  poNumber,
}) => {
  const { user, ctxStEquipments, setCtxStEquipments } = useContext(AuthContext);
  const [saveError, setSaveError] = useState("");
  const [agrProductCode, setAgreProductCode] = useState(null);
  const [agrPoNumber, setAgrPoNumber] = useState(null);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [disableCancelBtn, setDisableCancelBtn] = useState(false);
  const [eqiGrid, setEquiGrid] = useState(false);
  const [newBtn, setNewBtn] = useState(false);
  const [orgEquiments, setOrgEquipments] = useState([]);
  const [saveMsg, setSaveMsg] = useState("");

  console.log("select agreement : ", agreement);

  useEffect(() => {
    setAgreProductCode(productCode);
  }, [productCode]);

  useEffect(() => {
    setAgrPoNumber(poNumber);
  }, [poNumber]);

  const getEquipments = (agreementId) => {
    setDisableSaveBtn(true);
    console.log("AgmEquiplmentDialog", agreementId);
    setCtxStEquipments([]);
    setNewBtn(false);
    setLoadingMsg("Loading Equipment. Please Wait..");
    getAgreementEquipmentsList(agreementId)
      .then(
        (res) => {
          const eqList = res.data.body;
          const statusCode = res.status;
          if (statusCode === 200) {
            console.log("AgmEquiplmentDialog equipment list :", eqList);
            if (eqList && eqList.length > 0) {
              setCtxStEquipments(eqList);
              setOrgEquipments(eqList);
              setLoadingMsg("");
              setEquiGrid(true);
              setDisableSaveBtn(false);
            } else {
              setLoadingMsg("No Equipment found");
              setNewBtn(true);
              setDisableSaveBtn(true);
            }
          }
        },
        (err) => {
          console.log("unable to load equipment list", err);
          setSaveError("unable to load equipment list");
        }
      )
      .catch((err) => {
        console.log("Equipment List Internal Server Error", err);
        setSaveError("Internal Server error");
      });
  };

  useEffect(() => {
    if (optype === "update") {
      getEquipments(agreement?.id);
    } else {
      setEquiGrid(true);
    }
  }, [agreement?.id, optype]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    //resetDig();
    setEquiGrid(false);
    onClose();
  };

  const handleProductSelected = (param) => {
    console.log("selected product :", param);
    setAgreProductCode(param.productCode);
  };

  const handlePoNumberChange = (args) => {
    console.log("PoNumber change :", args);
    setAgrPoNumber(args);
  };

  const handleSave = () => {
    setSaveMsg("Saving Equipment Please wait...");
    let eqList = ctxStEquipments;
    setSaveError("");
    setDisableCancelBtn(true);
    setDisableSaveBtn(true);
    if (optype === "create") {
      eqList = eqList
        .map((eq) => ({ ...eq, id: "", createdBy: user.name }))
        .filter((eq) => eq.manufacturer !== null);
    } else {
      const saveEquIds = new Set(
        eqList.filter((eq) => eq.equipmentType !== "new").map((eq) => eq.id)
      );
      const deletedEquipments = orgEquiments
        .filter((oreq) => !saveEquIds.has(oreq.id))
        .map((eq) => {
          return { ...eq, isDeleted: 1 };
        });
      eqList = eqList
        .map((eq) => {
          if (eq.equipmentType === "new") {
            return { ...eq, id: "", createdBy: user.name };
          } else {
            return { ...eq, modifiedBy: user.name };
          }
        })
        .filter(
          (eq) =>
            eq.manufacturer !== null &&
            eq.manufacturer !== "null" &&
            eq.manufacturer.trim().length > 0
        );
      eqList = eqList.concat(deletedEquipments);
    }
    console.log("lenght of equipment list : ", eqList.length);
    if (!(eqList.length > 0)) {
      setSaveError("Please Enter Equipment Details.");
      setDisableCancelBtn(false);
      setDisableSaveBtn(false);
      setSaveMsg("");
      return;
    }
    console.log("json parse:", JSON.parse(JSON.stringify(eqList)));

    const reqJson = {
      agreementId: agreement?.id,
      optype: optype,
      equipmentList: eqList,
      agrPoNumber,
      agrProductCode,
    };
    addAgreementEquipments(agreement?.id, reqJson, optype)
      .then(
        (res) => {
          console.log("save data :", res.data);
          const statusCode = res.status;
          if (statusCode === 200) {
            setCtxStEquipments([]);
            setEquiGrid(false);
            setSaveMsg("");
            onClose("success");
          } else if (statusCode === 500) {
            console.log("unable to save the equipment", res.data);
            setSaveError("Error in saving Equipment list");
            setDisableCancelBtn(false);
            setDisableSaveBtn(false);
          }
        },
        (err) => {
          console.log("unable to save the equipment", err);
          setSaveError("unable to save the Equipment");
          setDisableCancelBtn(false);
          setDisableSaveBtn(false);
          setSaveMsg("");
        }
      )
      .catch((err) => {
        console.error("Internal server error :", err);
        setSaveError("Internal Server error");
        setDisableCancelBtn(false);
        setDisableSaveBtn(false);
        setSaveMsg("");
      });
    //setSaveMsg("");
  };
  const handleAddEquipmentsGrid = () => {
    setEquiGrid(true);
    setNewBtn(false);
    setLoadingMsg("");
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        component: "div",
      }}
      fullWidth={true}
      maxWidth={"xl"}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {optype === "create" ? "Add " : "Edit "}Equipment
          </Grid>

          <Grid item xs={8} align="right">
            <Stack direction="row" spacing={2}>
              <TextField
                id="Po-no"
                label="Po Number"
                variant="standard"
                value={agrPoNumber}
                onChange={(e) => handlePoNumberChange(e.target.value)}
              />
              <TblProductsSearch
                searchValue={agrProductCode}
                onProductSelected={handleProductSelected}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AgreementInfo agreement={agreement} />
          </Grid>
          <Grid item xs={12}>
            {eqiGrid && <EquipmentsTable selectedInvoice={agreement} />}

            {loadingMsg && (
              <Typography
                variant="info"
                component="subtitle1"
                align="left"
                sx={{ fontSize: "14px" }}
              >
                {loadingMsg}{" "}
                {newBtn && (
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={handleAddEquipmentsGrid}
                  >
                    Add Equipment
                  </Button>
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item>
            {saveError && (
              <Typography
                variant="warning"
                component="span"
                sx={{ ml: "10px" }}
              >
                {saveError}
              </Typography>
            )}
            {saveMsg && (
              <Typography
                variant="warning"
                component="span"
                sx={{ ml: "10px" }}
              >
                {saveMsg}
                {/* <GradientCircularProgress size="20px" /> */}
              </Typography>
            )}
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item>
            <Button onClick={handleSave} disabled={disableSaveBtn}>
              Save
            </Button>
            <Button onClick={handleClose} disabled={disableCancelBtn}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AgreementEquimentDialog;
